<script setup lang="ts">
import type { PaymentCardType } from '~/type/PaymentCard'

interface CardType {
  type: PaymentCardType
}

defineProps<CardType>()

function logo(type: string) {
  return new URL(`./asset/${type.toLowerCase()}.svg`, import.meta.url).href
}
</script>

<template>
  <img
    :src="logo(type || 'empty')"
    :alt="type || 'Логотип карты'"
  >
</template>
